"use client";

import type { ReactNode } from "react";
import { Anchor, ModalProvider, NProgress } from "@/components/common";

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ModalProvider modals={[]}>{children}</ModalProvider>
      <NProgress />
      <Anchor />
    </>
  );
};
